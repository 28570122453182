table.month {
    text-align: center;
}

td.disabled {
    color: lightgray;
}

td.available-std {
    background-color: lightgreen;
}

td.available-peak {
    background-color: yellow;
}

td.available-partner-std {
    background-color: orange;
}

td.available-partner-peak {
    background-color: red;
}

td.has-stops {
    opacity: 0.5;
}

.formrow-mg-bottom {
    margin-bottom: 10px;
}

.smalltxt {
    font-size: 10px;
}